<template>
  <div class="">
    <div class="col-12">
      <div class="card">
        <slot name="start">
          <Toolbar class="tw-mb-8 tw-p-3">
            <template #start>
              <div>
                <Button
                  label="Tambah"
                  icon="pi pi-plus"
                  class="p-button-sm p-button-success tw-bg-blue-light"
                  @click="navigateToCreate"
                />
              </div>
            </template>
          </Toolbar>
        </slot>
        <div class="text-xl font-medium text-black mb-3">
          <p>Layanan Salon</p>
        </div>
        <DataTable
          :value="salonServices"
          :paginator="true"
          :rows="10"
          dataKey="id"
          :rowHover="true"
          v-model:filters="filters1"
          filterDisplay="menu"
          :filters="filters1"
          responsiveLayout="scroll"
          :globalFilterFields="[
            'no',
            'salon_name',
            'category_name',
            'name',
            'service_desc',
            'estimated_time',
            'admin_fee',
            'status'
          ]"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rowsPerPageOptions="perPageOptions"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        >
          <template #header>
            <div class="flex justify-content-between flex-column sm:flex-row">
              <Button
                type="button"
                icon="pi pi-filter-slash"
                label="Hapus Filter"
                class="p-button-outlined mb-2"
                @click="clearFilter()"
              />
              <span class="p-input-icon-left mb-2">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters1['global'].value"
                  placeholder="Kata kunci"
                  style="width: 100%"
                />
              </span>
            </div>
          </template>
          <template #empty>
            <div
              class="
                tw-flex
                tw-justify-center
                tw-items-center
                tw-py-20
                tw-text-2xl
                tw-font-bold
              "
            >
              Tidak ada data yang ditemukan
            </div>
          </template>
          <template #loading>
            <div class="tw-flex tw-justify-center tw-items-center tw-py-20">
              Loading records, please wait...
            </div>
          </template>
          <Column field="no" header="No." style="min-width: 2rem">
            <template #body="{ data }">
              {{ data.no }}
            </template>
            <template #filter="{ filterModel }">
              <InputText
                type="text"
                v-model="filterModel.value"
                class="p-column-filter"
                placeholder="Search by number"
              />
            </template>
          </Column>
          <Column
            header="Nama Salon"
            filterField="salon_name"
            style="min-width: 12rem"
          >
            <template #body="{ data }">
              {{ data.salon_name }}
            </template>
            <template #filter="{ filterModel }">
              <InputText
                type="text"
                v-model="filterModel.value"
                class="p-column-filter"
                placeholder="Search by salon name"
              />
            </template>
          </Column>
          <Column
            header="Kategori"
            filterField="category_name"
            style="min-width: 12rem"
          >
            <template #body="{ data }">
              {{ data.category_name }}
            </template>
            <template #filter="{ filterModel }">
              <InputText
                type="text"
                v-model="filterModel.value"
                class="p-column-filter"
                placeholder="Search by category name"
              />
            </template>
          </Column>
          <Column header="Nama Layanan" filterField="name" style="min-width: 14rem">
            <template #body="{ data }">
              {{ data.name }}
            </template>
            <template #filter="{ filterModel }">
              <InputText
                type="text"
                v-model="filterModel.value"
                class="p-column-filter"
                placeholder="Search by name"
              />
            </template>
          </Column>
          <Column
            header="Deskripsi"
            filterField="service_desc"
            style="min-width: 12rem"
          >
            <template #body="{ data }">
              {{ data.service_desc }}
            </template>
            <template #filter="{ filterModel }">
              <InputText
                type="text"
                v-model="filterModel.value"
                class="p-column-filter"
                placeholder="Search by description"
              />
            </template>
          </Column>
          <Column
            header="Estimasi Waktu"
            filterField="estimated_time"
            style="min-width: 12rem"
          >
            <template #body="{ data }">
              {{ `${data.estimated_time} minutes` }} 
            </template>
            <template #filter="{ filterModel }">
              <InputText
                type="text"
                v-model="filterModel.value"
                class="p-column-filter"
                placeholder="Search by number"
              />
            </template>
          </Column>
          <!-- <Column
            header="Biaya Admin"
            filterField="admin_fee"
            style="min-width: 10rem"
          >
            <template #body="{ data }">
              {{ formatCurrency(data.admin_fee) }}
            </template>
            <template #filter="{ filterModel }">
              <InputText
                type="text"
                v-model="filterModel.value"
                class="p-column-filter"
                placeholder="Search by number"
              />
            </template>
          </Column> -->
          <Column header="Status" filterField="status" style="min-width: 11rem">
            <template #body="{ data }">
              <div>
                <Tag class="mr-2" severity="danger" value="Tidak Aktif" v-if="data.status === 0"></Tag>
                <Tag class="mr-2" severity="success" value="Aktif" v-else-if="data.status === 1"></Tag>
              </div>
            </template>
          </Column>
          <Column
            header="Aksi"
            :showFilterMatchModes="false"
            :filterMenuStyle="{ width: '14rem' }"
            style="min-width: 14rem"
          >
            <template #body="{ data }">
              <Button
                icon="pi pi-eye"
                class="p-button-rounded p-button-info"
                @click="detailData(data.id)"
              />
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-warning ml-1"
                @click="editData(data.id)"
              />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-danger ml-1"
                @click="confirmDelete(data)"
              />
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>

  <Dialog
    header="Korfirmasi"
    v-model:visible="showDeleteModal"
    :style="{width: '350px'}"
    :modal="true"
  >
    <div class="flex align-items-center justify-content-center">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
      <span>Apakah Anda yakin ingin melanjutkan?</span>
    </div>
    <template #footer>
      <Button label="Tidak" icon="pi pi-times" @click="showDeleteModal = false" class="p-button-text"/>
      <button class="p-button p-component tw-px-10" disabled v-if="loadingDelete">
        <ProgressSpinner
          stroke-width="5"
          animation-duration="2s"
          style="width: 30px; height: 30px;" />
      </button>
      <Button label="Ya" icon="pi pi-check" @click="deleteService(dataTemp)" class="p-button" autofocus v-else/>
    </template>
  </Dialog>
</template>

<script>
import { FilterMatchMode, FilterOperator } from "primevue/api";
import SalonServiceService from "@/service/SalonServiceService";
import { useCommonUtils } from "@/composables/utils";
export default {
  setup() {
    const { formatCurrency } = useCommonUtils();
    return { formatCurrency };
  },
  props: {
    perPageOptions: {
      type: Array,
      required: false,
      default() {
        return [10, 20, 50];
      },
    },
  },
  data() {
    return {
      salonServices: [],
      filters1: null,
      dataTemp: null,
      showDeleteModal: false,
      loadingDelete: false,
    };
  },
  salonService: null,
  created() {
    this.salonService = new SalonServiceService();
    this.initFilters();
  },
  mounted() {
    this.loadData();
  },
  methods: {
    navigateToCreate() {
      this.$router.push({ name: "create-salon-service" });
    },
    editData(id) {
      this.$router.push({ name: "edit-salon-service", params: { id: id } });
    },
    detailData(id) {
      this.$router.push({ name: "detail-salon-service", params: { id: id } });
    },
    loadData() {
      this.salonService.getAll().then((response) => {
        if (response.code === 200) {
          this.salonServices = response.data.salon_services;
          const temp = [];
          this.salonServices.forEach((salon_services, idx) => {
            temp.push({ ...salon_services, no: idx + 1 });
          });
          this.salonServices = temp;
        }
      });
    },
    confirmDelete(data) {
      this.dataTemp = data
      this.showDeleteModal = true
    },
    deleteService(data) {
      this.loadingDelete = true
      this.$http
        .delete(`${process.env.VUE_APP_PRITI_MAIN_API}/api/v1/services/${data.id}`)
        .then(response => {
          this.loadingDelete = false
          this.showDeleteModal = false
          if (response.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Success',
              detail: response.data.message,
              life: 3000,
            });
            let index = this.salonServices.findIndex(el => el.id === data.id)
            this.salonServices.splice(index, 1)
          } else {
            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: response.data.message,
              life: 3000,
            });
          }
        }).catch(e => {
          this.loadingDelete = false
          this.showDeleteModal = false
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: e.response.data.message,
            life: 3000,
          });
        })
    },
    initFilters() {
      this.filters1 = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        no: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        salon_name: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        category_name: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        name: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        service_desc: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        estimated_time: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.EQUALS },
          ],
        },
        admin_fee: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.EQUALS },
          ],
        },
      };
    },
    clearFilter() {
      this.initFilters();
    },
  },
};
</script>

<style scoped lang="scss">
</style>
